<template>
    <v-row v-resize="onResize" no-gutters class="fill-height">
        <v-col
            ref="menu"
            cols="12"
            md="3"
            sm="12"
            lg="3"
            class="border-right d-flex flex-column flex-nowrap"
            :style="`height: ${height}px;`"
        >
            <v-row no-gutters class="border-bottom flex-grow-0">
                <!-- Search -->
                <v-col class="flex-grow-1 pa-2">
                    <v-text-field
                        v-model="search"
                        clearable
                        dense
                        filled
                        hide-details="auto"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        rounded
                    />
                </v-col>
                <v-col class="flex-grow-0 pa-1 d-flex align-center justify-end">
                    <v-btn
                        depressed
                        class="mx-2"
                        color="primary"
                        rounded
                        @click="dialogNewScope = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom flex-grow-0">
                <!-- Sort -->
                <v-col
                    cols="12"
                    class="py-2 px-2  d-flex align-center justify-start"
                >
                    <v-select
                        flat
                        :items="categories"
                        item-text="name"
                        item-value="name"
                        hide-details
                        v-model="categoryFilter"
                        solo
                    />
                </v-col>
            </v-row>
            <!-- Loader -->
            <div
                v-if="loading"
                class="d-flex align-center justify-center flex-grow-1"
            >
                <v-progress-circular color="primary" indeterminate />
            </div>
            <!-- Scopes list -->
            <v-list v-else class="overflow-y-auto pa-0">
                <template v-if="filteredScopes.length > 0">
                    <div v-for="(scope, i) in filteredScopes" :key="scope.id">
                        <v-sheet
                            min-height="100"
                            class="fill-height"
                            color="transparent"
                        >
                            <v-lazy
                                :v-model="scope"
                                :options="{
                                    threshold: 0.5,
                                }"
                                class="fill-height"
                            >
                                <v-list-item
                                    color="primary"
                                    three-line
                                    link
                                    @click="handleClick(scope)"
                                    :class="{
                                        'border-bottom':
                                            i < filteredScopes.length - 1,
                                        'selected-item':
                                            selectedScope === scope.id,
                                    }"
                                >
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="mb-2"
                                            ><span style="font-weight: bold;"
                                                >Updated: </span
                                            >{{
                                                scope.lastDate
                                            }}</v-list-item-subtitle
                                        >
                                        <v-list-item-title class="mb-2">
                                            <span style="font-weight: bold;"
                                                >Project:</span
                                            >
                                            {{
                                                scope.quoteData?.name ??
                                                    'Default Project'
                                            }}</v-list-item-title
                                        >
                                        <v-list-item-title class="mb-2">
                                            <span style="font-weight: bold;"
                                                >Scope:</span
                                            >
                                            {{
                                                scope.reference
                                            }}</v-list-item-title
                                        >
                                    </v-list-item-content>
                                    <v-btn
                                        icon
                                        @click.stop="openDeleteDialog(scope)"
                                        class="ml-auto"
                                        aria-label="Delete Scope"
                                    >
                                        <v-icon>mdi-trash-can</v-icon>
                                    </v-btn>
                                </v-list-item>
                            </v-lazy>
                        </v-sheet>
                    </div>
                </template>
                <v-list-item v-else>
                    <v-list-item-content>
                        <v-list-item-title class="text-center">
                            No quotes
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
        <v-col
            ref="content"
            cols="12"
            sm="0"
            md="9"
            lg="9"
            class="overflow-y-auto"
            :style="`height: ${height}px`"
        >
            <router-view :key="viewKey" @show="show" />
        </v-col>
        <v-dialog
            :retain-focus="false"
            v-model="createDialogNewCost"
            persistent
            max-width="1200px"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <NewScopeForm
                v-if="createDialogNewCost"
                :originalCost="{}"
                :createCostForm="createCostForm"
                title="New Scope"
                :costs="scopes"
                :cost="newItemCost"
                :loading="loading"
                :settings="settings"
                :action="action"
                @closeDialog="closeDialog"
            />
        </v-dialog>
        <v-dialog
            :retain-focus="false"
            v-model="dialogDelete"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Delete Scope</v-card-title>
                <v-card-text>
                    Do you want to delete the scope?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteDialog">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteScope"
                        text
                        color="error"
                        :loading="loading2"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            :retain-focus="false"
            v-model="dialogNewScope"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5 py-3">
                    <v-btn
                        class="mr-1"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="dialogNewScope = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <span>Create Scope</span>
                </v-card-title>
                <v-card-text>
                    Select an option to create a new scope.
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mt-2" plain @click="openImportDialog">
                        <v-icon>mdi-import</v-icon>
                        <span class="mt-1">Import</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-2" text plain @click="newCost">
                        <v-icon>mdi-plus</v-icon>
                        <span class="mt-1">Create</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="importDialog" max-width="800px">
            <ImportCosts
                v-if="importDialog"
                :scopeMode="true"
                @importScopes="importScopes"
                @close="importDialog = false"
            />
        </v-dialog>
    </v-row>
</template>

<script>
import API from '@/services/api'
import moment from 'moment'
import _ from 'lodash'
import NewScopeForm from '@/components/Scopes/NewScopeForm'
import ImportCosts from '../../components/Quotes/ImportCosts.vue'
import { mapGetters } from 'vuex/dist/vuex.common.js'

export default {
    name: 'Scopes',
    components: {
        NewScopeForm,
        ImportCosts,
    },
    data() {
        return {
            categoryFilter: 'All',
            selectedScope: null,
            importDialog: false,
            dialogNewScope: false,
            dialogDelete: false,
            viewKey: false,
            loading: false,
            height: 0,
            search: null,
            quote: null,
            scope: null,
            scopeToDelete: null,
            editScope: null,
            editScopes: null,
            loadingError: false,
            loading2: false,
            errorMsg: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            userId: JSON.parse(localStorage.getItem('userId')),
            users: [],
            scopes: [],
            action: { active: false },
            settings: [],
            categories: [
                {
                    description: 'All categories',
                    name: 'All',
                },
            ],
            units: [],
            createCostForm: false,
            originalCost: null,
            createDialogNewCost: false,
            costToEdit: null,
            newItemCost: {
                totalSections: [],
                sections: [],
                total: 0,
                total2: 0,
                totalMargin2: 0,
                commission2: 0,
                quantity2: 0,
                salePrice2: 0,
                pricePerQuantity2: 0,
                margin: 40,
                measure: 'ft2',
                totalMargin: 0,
                commission: 0,
                salePrice: 0,
                pricePerQuantity: 0,
                american: {
                    tw: 0,
                    wa: 0,
                    cw: 0,
                    pw: 0,
                    ca: 0,
                },
                international: {
                    tw: 0,
                    wa: 0,
                    cw: 0,
                    pw: 0,
                    ca: 0,
                },
            },
        }
    },
    computed: {
        ...mapGetters(['getScopeInfo']),
        filteredScopes() {
            return this.scopes.filter(q => {
                if (this.search && this.categoryFilter === 'All') {
                    return (q.reference + ' ' + q.quoteData?.name ?? '')
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                } else if (this.search && this.categoryFilter !== 'All') {
                    return (
                        (q.reference + ' ' + q.quoteData?.name ?? '')
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) &&
                        q.category === this.categoryFilter
                    )
                } else if (!this.search && this.categoryFilter !== 'All') {
                    return q.category === this.categoryFilter
                } else {
                    return this.scopes
                }
            })
        },
    },
    watch: {
        action: function() {
            if (this.action.active) {
                setTimeout(() => {
                    this.action.active = false
                }, 5000)
            }
        },
        getScopeInfo(actionScope) {
            if (actionScope && this.userId === actionScope.userId) {
                if (actionScope.type === 'create') {
                    this.createScope(actionScope.data)
                } else if (actionScope.type == 'update') {
                    this.updateScope(actionScope.data)
                }
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            await this.loadData()
        } catch (error) {
            console.log(error)
        } finally {
            this.loading = false
        }
    },
    methods: {
        async loadData() {
            try {
                this.loading = true
                if (this.$route.name === 'scopeForm') {
                    this.$router.push({
                        name: 'noScopes',
                    })
                }
                this.scopes = await API.getScopes()
                for (const scope of this.scopes) {
                    if (scope.updatedOn) {
                        scope.lastDate = scope.updatedOn
                    } else {
                        scope.lastDate = scope.createdOn
                    }
                }
                this.sortObjectsByDate()
                for (const scope of this.scopes) {
                    scope.lastDate = moment
                        .unix(scope.lastDate._seconds)
                        .format('MMM/DD/YYYY h:mm a')
                }
                const {
                    data: { settings },
                } = await API.getSettings()
                this.settings = settings
                const invoiceSettings = this.settings.find(
                    setting => setting.name.toLowerCase() == 'invoicing'
                )
                invoiceSettings.categories.forEach(category =>
                    this.categories.push(category)
                )
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        handleClick(scope) {
            this.selectScope(scope.id)
            this.hide(scope)
        },
        selectScope(id) {
            this.selectedScope = id
        },
        sortObjectsByDate() {
            this.scopes.sort((a, b) => {
                const aTimestamp =
                    a.lastDate._seconds * 1000 +
                    a.lastDate._nanoseconds / 1000000
                const bTimestamp =
                    b.lastDate._seconds * 1000 +
                    b.lastDate._nanoseconds / 1000000
                return bTimestamp - aTimestamp
            })
        },
        importScopes(scopes) {
            try {
                scopes.forEach(newScope => {
                    newScope.createdOn = moment
                        .unix(newScope.createdOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')
                    newScope.lastDate = newScope.createdOn
                    this.scopes.unshift(newScope)
                })
                this.dialogNewScope = false
                this.importDialog = false
            } catch (error) {
                console.error(error)
            }
        },
        onResize() {
            this.height =
                window.innerHeight -
                this.$vuetify.application.top -
                this.$vuetify.application.footer
        },
        show() {
            this.$refs.menu.classList.remove('d-none')
            this.$refs.menu.classList.add('d-flex')
        },
        hide(scope) {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$refs.menu.classList.remove('d-flex')
                this.$refs.menu.classList.add('d-none')
            }
            this.viewKey = !this.viewKey
            this.originalCost = _.cloneDeep(scope)
            this.editScope = _.cloneDeep(scope)
            this.editScopes = _.cloneDeep(this.scopes)

            const newRoute = {
                name: 'scopeForm',
                params: {
                    id: scope.id,
                    originalCost: this.originalCost,
                    title: 'Edit Scope',
                    costs: this.editScopes,
                    cost: this.editScope,
                    loading: this.loading,
                    settings: this.settings,
                    action: this.action,
                    closeButton: false,
                    editScope: true,
                },
            }
            if (
                this.$route.name !== newRoute.name ||
                this.$route.params.id !== newRoute.params.id
            ) {
                this.$router.push(newRoute)
            }
        },
        newCost() {
            if (this.$route.name != 'noScopes') {
                this.$router.push({
                    name: 'noScopes',
                })
            }
            this.dialogNewScope = false
            this.createDialogNewCost = true
            this.createCostForm = true
        },
        closeDialog() {
            this.createDialogNewCost = false
            this.alternative = false
            this.costToEdit = {}
            this.editAlternative = false
            this.newItemCost = {
                totalSections: [],
                sections: [],
                total: 0,
                total2: 0,
                totalMargin2: 0,
                commission2: 0,
                quantity2: 0,
                salePrice2: 0,
                pricePerQuantity2: 0,
                margin: 40,
                measure: 'ft2',
                totalMargin: 0,
                commission: 0,
                salePrice: 0,
                pricePerQuantity: 0,
                american: {
                    tw: 0,
                    wa: 0,
                    cw: 0,
                    pw: 0,
                    ca: 0,
                },
                international: {
                    tw: 0,
                    wa: 0,
                    cw: 0,
                    pw: 0,
                    ca: 0,
                },
            }
        },

        createScope: async function(scope) {
            try {
                const newScope = await API.createScope(scope)
                newScope.createdOn = moment
                    .unix(newScope.createdOn._seconds)
                    .format('MMM/DD/YYYY h:mm a')
                newScope.lastDate = newScope.createdOn
                this.scopes.unshift(newScope)
                this.dialogNewScope = false
                this.importDialog = false
            } catch (error) {
                console.error(error)
            }
        },
        updateScope: async function(scope) {
            try {
                const updateScope = await API.updateScope(scope)
                const indexScope = this.scopes.findIndex(
                    obj => obj.id === updateScope.id
                )
                if (indexScope > -1) {
                    this.scopes.splice(indexScope, 1)
                    updateScope.lastDate = updateScope.updatedOn
                    updateScope.lastDate = moment
                        .unix(updateScope.lastDate._seconds)
                        .format('MMM/DD/YYYY h:mm a')
                    this.scopes.unshift(updateScope)
                }
            } catch (error) {
                console.error(error)
            }
        },
        openDeleteDialog(scope) {
            this.scopeToDelete = _.cloneDeep(scope)
            this.dialogDelete = true
        },
        closeDeleteDialog() {
            this.scopeToDelete = null
            this.dialogDelete = false
        },
        async deleteScope() {
            try {
                this.loading2 = true
                const indexScope = this.scopes.findIndex(
                    obj => obj.id === this.scopeToDelete.id
                )
                if (
                    this.$route.name === 'scopeForm' &&
                    this.$route.params.id === this.scopeToDelete.id
                ) {
                    this.$router.push({
                        name: 'noScopes',
                    })
                }
                if (indexScope > -1) {
                    this.scopes.splice(indexScope, 1)
                }
                await API.deleteScope(this.scopeToDelete.id)
                this.scopeToDelete = null
                this.dialogDelete = false
            } catch (error) {
                console.error(error)
            } finally {
                this.loading2 = false
            }
        },
        openImportDialog() {
            if (this.$route.name != 'noScopes') {
                this.$router.push({
                    name: 'noScopes',
                })
            }
            this.importDialog = true
        },
    },
}
</script>

<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.selected-item {
    background-color: rgba(0, 123, 255, 0.1); /* Fondo azul claro */
    border-left: 4px solid #007bff; /* Línea de selección */
}
</style>
